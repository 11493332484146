import { createGlobalStyle } from 'styled-components';
import theme from '../theme';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Noto+Serif');

  body,
  html { 
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: ${theme.bodyColor};
    font-family: sans-serif;
    font-kerning: normal;
    font-size: 1em;
    font-size: calc(14px + (16 - 14) * ((100vw - 576px) / (1240 - 300)));
    font-variant-ligatures: common-ligatures;
    line-height: 1.43;
    margin: 0;
    overflow-x: hidden;
    padding: 0;
    text-rendering: optimizeLegibility;
    width: 100%;
  }

  body,
  html,
  article,
  main,
  figure,
  aside,
  section,
  label, 
  input,
  select,
  footer, 
  header, 
  img,
  div:not(.slick-track) {
    box-sizing: border-box;
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5, 
  h6 { 
    color: ${theme.dark};
    font-family: 'Noto Serif', serif;
    font-weight: 600;
    line-height: 1.2;
  }

  h1 {
    font-size: 2.645em;
    @media (max-width: 768px){
      font-size: 2.22em;
      margin-top: 2rem !important;
    }
  }

  h2 {
    font-size: 2.178em;
    margin-top: 0;
  }

  h3 {
    font-size: 1.476em;
    margin-bottom: .5rem;
    margin-top: 1rem;
  }

  h4 {
    font-size: 1.383em;
  }

  h5 {
    font-size: 1.296em;
  }

  h6 {
    font-size: 1.215em;
  }

  p,
  li { 
    letter-spacing: 0.65px;
    font-size: 1em;
    font-weight: 100;

    p {
      margin: 0;
    }
  }

  small,
  button,
  a {
    font-size: .823em;
  }

  a {
    font-weight: bold;
    color: ${theme.tertiary};
    transition: color 500ms;
    text-decoration: none;
    position: relative;

    &:hover, 
    &:focus {
      color: ${theme.dark};
    }

    &:active {
      color: ${theme.secondary};
    }
  }

  b {
    color: ${theme.tertiary};
    text-transform: uppercase;
  }

  #body {
    b {
      text-transform: none;
    }
  }

  input:not([type="checkbox"]),
  input:not([type="radio"]),
  select {
    appearance: none;
    background-color: #f5f8fa;
    border: 0;
    border: 2px solid ${theme.darkMuted};
    line-height: 25px;
    height: 50px;
    outline: 0;
    padding: 0 1rem;
    transition: border-color 500ms;
    width: 100%;
    
    &:focus {
      border-color: ${theme.secondary};
    }
  }

  .slick-track {
    align-items: center !important;
    display: flex !important;
  }

  .slick-slide {
    line-height: 0;
  }
`;
