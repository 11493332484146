import React from 'react';
import PropTypes from 'prop-types';
import Footer from './Footer';
import StyleSheet from './StyleSheet';
import Navigation from './Nav';
import { ColourContextWrapper } from './NavBar';
import Announcement from './Announcement';

const App = ({ children }) => (
  <ColourContextWrapper>
    <StyleSheet />
    <Announcement />
    <Navigation />
    <main>
      {children}
    </main>
    <Footer />
  </ColourContextWrapper>
);

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default App;
