import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql, Link } from "gatsby";
import { FiDownload, FiArchive, FiInfo } from "react-icons/fi";
import { ListNormalized } from "./List";
import theme from "../theme";

export const getIcon = (href, size = "1.5rem") => {
  switch (href) {
    case "blog":
      return <FiArchive size={size} />;
    case "resources":
      return <FiDownload size={size} />;
    case "word-lx":
      return <img src="/wordlxDark.png" alt="WordLX Logo" />;
    case "inform":
      return <img src="/informDark.png" alt="InForm Logo" />;
    case "https://www.infowareit.ca/":
      return (
        <img
          src="https://uploads-ssl.webflow.com/60b66838e28db47c1db95fbe/60b77c69692ce11b5ec2037f_infowarelogomaintransparent.png"
          alt="Managed IT Services"
        />
      );
    default:
      return <FiInfo size={size} />;
  }
};

const SubNavItem = styled.li`
  display: inline-block;
  padding: 1rem;
  margin: 0;
  text-align: center;

  svg {
    display: block;
    margin: 0 auto 0.25rem;
    width: 2.5rem;
  }
`;

const SubNavItemLink = styled(Link)`
  border: 2px solid transparent;
  border-radius: ${theme.borderRadius};
  color: ${theme.muted};
  display: block;
  padding: 1rem 2rem;
  text-decoration: none;
  transition-duration: 1s;
  transition-property: border-color, color;

  &:focus,
  &:hover {
    border-color: ${theme.mutedText};
    color: ${theme.mutedText};
  }
  &.active {
    border-color: ${theme.contrast};
    color: ${theme.contrast};
  }
`;

const query = graphql`
  query {
    site {
      siteMetadata {
        resourcesMenu {
          label
          href
        }
      }
    }
  }
`;

const ResourcesSubNav = () => (
  <StaticQuery
    query={query}
    render={({ site }) => {
      const { siteMetadata } = site;
      const { resourcesMenu = [] } = siteMetadata;

      return (
        <ListNormalized>
          {resourcesMenu.map(({ href, label }) => (
            <SubNavItem key={href}>
              <p>
                <SubNavItemLink to={href} activeClassName="active">
                  {getIcon(href)}
                  {label}
                </SubNavItemLink>
              </p>
            </SubNavItem>
          ))}
        </ListNormalized>
      );
    }}
  />
);

export default ResourcesSubNav;
