import styled, { css } from 'styled-components';
import theme from '../theme';

export const ListNormalized = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const ListNormalizedItem = styled.li`
    margin-bottom: .5rem;
`;

export const ListItemInline = styled.li`
    display: inline-block;
`;

export const styledList = css`
  ul { 
    list-style: none;
    margin: 1rem 0 2rem;
    padding: 0 0 0 1rem; 
    text-align: left;

    li {  
      p {
        font-size: 0.95em;
      }

      margin: 0 0 0.25rem; 
      position: relative;
      padding-left: 2rem;

      &:before {
        background-color: ${theme.success}20;
        color: ${theme.success}; 
        border-radius: 50%;
        box-sizing: border-box;
        font-size: 0.7rem;
        content: '✓';
        font-weight: bold;
        display: inline-block;
        height: 1.15rem;
        width: 1.15rem;
        left: 0;
        top: 0;
        text-align: center;
        line-height: 1.15rem;
        position: absolute;
      }

      p { 
        margin: 0; 
      }
    }
  }
`;

export const TwoColumnList = styled.div`
  ${styledList};

  ul li {
    box-sizing: border-box;
    float: left;
    width: 50%;

    @media (max-width: 567px){
      float: none;
      width: 100%;
    }
  }
`;
