import React from "react";
import PropTypes from "prop-types";

export const getVideoHostLink = (url) => {
  const capture = (re) => {
    const match = url.match(new RegExp(re));
    return match ? match[1] : null;
  };

  const buildYouTubeSrc = () => {
    const id =
      capture(/^.*\/watch\?v=(([a-zA-Z]|[0-9]){11})*/) ||
      capture(/^.*youtu\.be\/(([a-zA-Z]|[0-9]){11})*/);

    return id ? `//www.youtube.com/embed/${id}` : null;
  };

  const buildVimeoSrc = () => {
    const id = capture(/^.*vimeo\.com\/(([a-zA-Z]|[0-9]){9})*/);
    return id ? `https://player.vimeo.com/video/${id}` : null;
  };

  if (typeof url === "string") {
    if (url.includes("yout")) return buildYouTubeSrc();
    if (url.includes("vimeo")) return buildVimeoSrc();
  }

  return null;
};

function EmbeddedVideo({ src }) {
  return (
    <iframe
      src={src}
      width="100%"
      height="360px"
      frameBorder="0"
      style={{ borderRadius: 4 }}
      allow="autoplay; fullscreen; picture-in-picture"
    ></iframe>
  );
}

EmbeddedVideo.propTypes = {
  src: PropTypes.string.isRequired,
};

EmbeddedVideo.defaultProps = {};

export default EmbeddedVideo;
