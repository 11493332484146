import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import styled, { css } from 'styled-components';
import RichText from './RichText';
import theme from '../theme';
import globalProps from '../props';

const Border = styled.div`
  border: 2px solid ${theme.muted};
  border-radius: ${theme.borderRadius};
  padding: 2vw;
  text-align: center;
  transition: border-color 250ms;

  &:hover {
    border-color: ${theme.primary}25;
  }
`;

export const Label = styled.p`
  color: ${theme.tertiary};
  font-size: 1.131em;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
`;

const AuthorName = styled.h2`
  font-family: inherit;
  font-weight: 900;
  font-size: 1.333em;
  margin-top: 0;
`;

export const AuthorImageBio = styled.figure`
  align-items: center;
  display: flex;
  margin: 1rem 0 0;
`;

export const AuthorImage = styled.div`
  border-radius: 50%;
  display: inline-block;
  margin-right: 1rem;
  overflow: hidden;
  position: relative;

  ${({ large }) => {
    const unit = large ? '6rem' : '3rem';
    return css`
      height: ${unit};
      width: ${unit};
    `;
  }};
`;

const AuthorWidget = ({ name, thumbnail, bio }) => (
  <Border>
    <Label style={{ margin: 0 }}>
      {'About the Author'}
    </Label>
    <AuthorName>
      {name}
    </AuthorName>
    {thumbnail && (
      <AuthorImage large>
        <Image {...thumbnail} />
      </AuthorImage>
    )}
    <RichText body={bio} />
  </Border>
);

AuthorWidget.propTypes = {
  name: PropTypes.string.isRequired,
  thumbnail: globalProps.image.isRequired,
  bio: globalProps.body.isRequired,
};

export default AuthorWidget;
