export default {
  bodyColor: '#55656d',
  headingColor: '#262D3C',
  contrast: 'rgba(255,255,255,1)',
  contrastOpacity: 'rgba(255,255,255,0.67)',
  dark: '#262D3C',
  muted: '#E6F2FA90',
  light: '#E2F9F8',
  boxShadow: '#262D3C10',
  primary: '#43C1E9',
  secondary: '#0073BE',
  tertiary: '#123E6F',
  borderRadius: '3px',
  success: '#a5d06c',
  darkMuted: '#e5f1ff',
};
