import styled, { css } from 'styled-components';
import theme from '../theme';

export default styled.div`
    ${({ border }) => {
    if (border) {
      return css`
        border: 2px solid ${theme.muted};
        margin: 0 auto 2rem !important;
        padding: 4vh 6vw;
      `;
    }

    return css`
      margin: 0 auto;
      padding: 2vh 3vw;
    `;
  }};

${({ center }) => {
    if (center) {
      return css`
        text-align: center;
      `;
    }
    return css``;
  }};

  margin: ${({ margin }) => margin ? '4vh auto' : '0 auto'};
  position: relative;
  width: ${({ large, small, xsmall }) => {
    let size = '1140px';
    if (small) {
      size = '852px';
    } else if (large) {
      size = '1640px';
    } else if (xsmall) {
      size = '725px';
    }

    return size;
  }};
`;
