import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const SEO = ({ title, description }) => (
  <Helmet>
    <html lang="en" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>{`${title} | Infoware`}</title>
    {description && (
      <meta
        name="description"
        content={description.description}
      />
    )}
    <script async>
      {`
        (function(w, d) {
          w.CollectId = "5b05698f52aa182439182d19";
          var h = d.head || d.getElementsByTagName("head")[0];
          var s = d.createElement("script");
          s.setAttribute("type", "text/javascript");
          s.setAttribute("src", "https://collectcdn.com/launcher.js");
          h.appendChild(s);
        })(window, document);
    `}
    </script>
  </Helmet>
);

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
};

SEO.defaultProps = {
  description: null,
};

export default SEO;
