import PropTypes from 'prop-types';

export default {
  body: PropTypes.shape({
    childContentfulRichText: PropTypes.shape({
      html: PropTypes.string,
    }),
  }),
  image: PropTypes.shape({
    alt: PropTypes.string,
    fluid: PropTypes.object,
  }),
  menuLinks: PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    subMenu: PropTypes.array,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
