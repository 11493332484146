import styled, { css } from "styled-components";
import theme from "../theme";

export const versionStyles = {
  cyan: css`
    background-size: cover;
    background-image: url("/corners.svg"),
      linear-gradient(to right, ${theme.primary}, ${theme.secondary});
    color: ${theme.contrast};
  `,
  blue: css`
    background-size: cover;
    background-image: url("/textureFromLogo.svg"),
      linear-gradient(to right, ${theme.secondary}, ${theme.tertiary});
    color: ${theme.contrast};
  `,
  green: css`
    background-size: cover;
    background-image: url("/corners.svg"),
      linear-gradient(to left, #89a762, ${theme.success});
    color: ${theme.contrast};
  `,
  purple: css`
    background-size: cover;
    background-image: url("/textureFromLogo.svg"),
      linear-gradient(to right, #503e79, #382d50);
    color: ${theme.contrast};
  `,
};

export default styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: none;

  ${({ align = "inital", justify = "initial" }) =>
    css`
      align-items: ${align};
      justify-content: ${justify};
    `};
`;

export const Half = styled.div`
  padding: 1rem;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Third = styled.div`
  padding: 1rem;
  width: 33%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ColouredHalf = styled.div`
  margin: 2rem 0;
  padding: 1vh 3vw;
  width: 50%;

  @media (max-width: 768px) {
    margin: 1rem 0;
    width: 100%;

    > article {
      width: 100%;
    }
  }
`;
