import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import { ListItemInline } from './List';

const SocialList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: 8rem;
  width: 14rem;
  text-align: right;
`;

const IconLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
    color: #262D3C;
    margin: .25rem .5rem;
`;

const socialData = graphql`
    query {
        site {
            siteMetadata {
                socialLinks {
                    linkedin 
                    twitter
                    facebook
                }
            }
        }
    }
`;

const SocialIcons = () => (
  <StaticQuery
    query={socialData}
    render={({ site }) => {
      const { siteMetadata } = site;
      const { socialLinks } = siteMetadata;

      return (
        <SocialList>
          <ListItemInline>
            <IconLink href={socialLinks.linkedin}>
              <FaLinkedinIn size="18" />
            </IconLink>
          </ListItemInline>
          <ListItemInline>
            <IconLink href={socialLinks.facebook}>
              <FaFacebookF size="18" />
            </IconLink>
          </ListItemInline>
          <ListItemInline>
            <IconLink href={socialLinks.twitter}>
              <FaTwitter size="18" />
            </IconLink>
          </ListItemInline>
        </SocialList>
      );
    }}
  />
);

export default SocialIcons;
