import React, { useEffect, useState } from "react";
import VisuallyHidden from "@reach/visually-hidden";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";
import { FiMenu, FiX, FiChevronRight } from "react-icons/fi";
import OffCanvas from "react-aria-offcanvas";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Container from "./Container";
import Logo from "./Logo";
import globalProps from "../props";
import theme from "../theme";
import Grid from "./Grid";
import { ListNormalized, ListNormalizedItem } from "./List";
import { getIcon } from "./ResourcesSubNav";

/** @NOTE HOC near bottom of file  */
const NavBarColourContext = React.createContext({});

const NavBarWrapper = styled.nav`
  background-color: transparent;
  left: 0;
  padding: 2vh 0;
  position: absolute;
  top: 45px;
  transition-duration: 500ms;
  transition-property: background-color;
  width: 100%;
  z-index: 100;

  @media (max-width: 768px) {
    top: auto;
  }

  ${({ active }) => {
    if (active) {
      return css`
        background-color: ${theme.contrast};
        box-shadow: 3px 2px 13px ${theme.boxShadow};
        padding: 0;
        position: fixed;
        top: 0 !important;
      `;
    }

    return null;
  }};

  a:hover {
    color: ${theme.muted};
  }
`;

const NavBarMenu = styled(ListNormalized)`
  @media (max-width: 866px) {
    display: none;
  }
`;

const NavBarMenuItems = styled.li`
  display: inline-block;

  &:last-child a {
    border: 1px solid;
    border-radius: ${theme.borderRadius};
    margin-left: 2vw;
    padding: 1rem 3vh;
    transition-duration: 250ms;
    transition-property: background, border-color, color;

    &:hover {
      background: ${theme.tertiary};
      border-color: ${theme.tertiary};
      color: ${theme.contrast};
    }
  }
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
        top: 75%;
    }
    to {
        opacity: 1;
    }
`;

const NavbarDropdown = styled.ul`
  animation: ${fadeIn} 500ms forwards;
  background-color: ${theme.contrast};
  box-shadow: 5px 16px 33px ${theme.dark}30;
  display: none;
  left: 50%;
  list-style: none;
  margin: 0;
  padding: 1rem 0.5rem;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 530px;

  &::after {
    border-bottom: 0.5rem solid ${theme.contrast};
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    bottom: 99%;
    left: 50%;
    content: "";
    height: 0;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
  }
`;

const NavbarMenuLinkWrapper = styled.div`
  display: block;
  position: relative;
  margin-right: 2vw;
  padding: 2vh 0;

  &:hover ul {
    display: flex !important;
  }

  &:focus-within ul {
    display: flex !important;
  }
`;

const NavbarDropdownItem = styled.li`
  background-color: ${theme.contrast};
  box-sizing: border-box;
  margin: 0;
  position: relative;
  width: 265px;
`;

const withExternalLink = (Component) => (props) => {
  const { to, ...rest } = props;

  return String(to).startsWith("http")
    ? React.createElement("a", {
        ...rest,
        href: to,
      })
    : React.createElement(Component, {
        ...rest,
        to: `/${to}`,
      });
};

const OffcanvasLink = withExternalLink(Link);

const NavbarDropdownLink = styled(withExternalLink(Link))`
  background: ${theme.muted};
  border: 0.25rem solid ${theme.contrast};
  box-sizing: border-box;
  color: ${theme.dark};
  display: block;
  height: 100%;
  padding: 1rem;
  text-decoration: none;
  transition-duration: 500ms;
  transition-property: background-color, color;

  svg {
    transition: stroke 250ms;
    width: 1rem;
  }

  &:hover,
  &:focus {
    background-color: ${theme.darkMuted};
    color: ${theme.tertiary} !important;
  }

  &:focus {
    outline: 0;
  }
`;

const DropDownIcon = styled.div`
  margin-right: 1rem;
  max-width: 5rem !important;

  img {
    width: 100%;
    max-width: 100%;
  }
`;

const DropDownTitle = styled.p`
  font-size: 1.067em;
  font-weight: 800;
`;

const DropDownSubText = styled.p`
  display: block;
  opacity: 0.71;
  font-size: 0.911em;
  margin: 0.5rem 0 !important;
  font-weight: 200;
`;

const NavbarMenuLinkElement = styled(Link)`
  color: ${(props) => (props.contrast ? theme.contrast : theme.dark)};
  font-size: 1rem;
  text-decoration: none;
`;

const OffCanvasMenuOpen = styled.button`
  background-color: ${theme.primary}20;
  border-radius: ${theme.bodyRadius};
  border: 0;
  display: none;
  padding: 0.5rem;

  svg {
    stroke: ${({ active }) => (active ? theme.dark : theme.contrast)};
  }

  @media (max-width: 866px) {
    display: block;
  }
`;

const OffCanvasClose = styled(OffCanvasMenuOpen)`
  background-color: transparent;
  position: absolute;
  right: 1rem;
  top: 1rem;

  svg {
    stroke: ${theme.dark} !important;
  }
`;

const AccordionList = styled(Accordion)`
  margin: 5rem 2rem;
`;

const AccordionTitle = styled(AccordionItemButton)`
  a {
    background-color: ${theme.muted};
    color: ${theme.dark};
    display: block;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    padding: 4vw 4vw;

    &:hover {
      color: ${theme.tertiary};
    }
  }
`;

const NavBar = ({ items, transparency }) => {
  const [scrollPos, setScrollPos] = useState(0);
  const [offcanvasState, setOffanvasState] = useState(0);
  const isActive = scrollPos > 45;
  const isTransparent = transparency && !isActive;

  useEffect(() => {
    function trackScrollPosition() {
      setScrollPos(window.pageYOffset);
    }

    document.addEventListener("scroll", trackScrollPosition);

    return () => {
      document.removeEventListener("scroll", trackScrollPosition);
    };
  }, []);

  return (
    <NavBarWrapper active={isActive}>
      <Container large>
        <Grid justify="space-between" align="center">
          <Link to="/">
            <Logo
              filter={isTransparent}
              src="/colourLogo.png"
              alt="Infoware Logo"
            />
          </Link>
          <NavBarMenu>
            {items.map((item, i) => (
              <NavBarMenuItems key={`${item.slug}-${i}`}>
                <NavbarMenuLinkWrapper>
                  <NavbarMenuLinkElement
                    contrast={isTransparent}
                    to={`/${item.href}`}
                    onClick={(e) => {
                      if (item.href === "#") {
                        e.preventDefault();
                      }
                    }}
                  >
                    {item.label}
                    {item.href === "#" && "▾"}
                  </NavbarMenuLinkElement>
                  {item.subMenu && (
                    <NavbarDropdown contrast={isTransparent}>
                      {item.subMenu.map((sub) => (
                        <NavbarDropdownItem key={sub.href}>
                          <NavbarDropdownLink to={sub.href}>
                            <Grid align="center">
                              <DropDownIcon>
                                {getIcon(sub.href)}
                                <VisuallyHidden label={sub.label} />
                              </DropDownIcon>
                              <DropDownTitle>
                                {getIcon(sub.href).type !== "img" && sub.label}
                              </DropDownTitle>
                            </Grid>
                            {sub.description && (
                              <DropDownSubText>
                                {sub.description.description || sub.description}
                              </DropDownSubText>
                            )}
                            <p>
                              <small>
                                <strong>{"Read More "}</strong>
                                <FiChevronRight size="10" />
                              </small>
                            </p>
                          </NavbarDropdownLink>
                        </NavbarDropdownItem>
                      ))}
                    </NavbarDropdown>
                  )}
                </NavbarMenuLinkWrapper>
              </NavBarMenuItems>
            ))}
          </NavBarMenu>
          <OffCanvasMenuOpen
            id="menu-button"
            aria-label="Menu"
            aria-controls="menu"
            aria-expanded={offcanvasState}
            onClick={() => setOffanvasState(true)}
            active={isActive}
          >
            <FiMenu size="32" />
          </OffCanvasMenuOpen>
        </Grid>
      </Container>
      <OffCanvas
        role="menu"
        isOpen={offcanvasState}
        labelledby="menu-button"
        height="100vh"
        position="right"
        style={{
          content: {
            background: theme.contrast,
          },
        }}
      >
        <OffCanvasClose
          aria-label="Close Offcanvas Menu"
          onClick={() => setOffanvasState(false)}
        >
          <FiX size="32" />
        </OffCanvasClose>
        <AccordionList>
          {items.map((item, i) => (
            <AccordionItem key={`${item.slug}-${i}`}>
              <AccordionItemHeading>
                <AccordionTitle>
                  <Link
                    to={`/${item.href}`}
                    onClick={(e) => {
                      if (item.href === "#") {
                        e.preventDefault();
                      }
                    }}
                  >
                    {item.label}
                  </Link>
                </AccordionTitle>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {item.subMenu && (
                  <ListNormalized style={{ margin: "1rem 1rem 2rem 2rem" }}>
                    {item.subMenu.map((sub) => (
                      <ListNormalizedItem key={sub.href}>
                        <OffcanvasLink to={sub.href}>{sub.label}</OffcanvasLink>
                      </ListNormalizedItem>
                    ))}
                  </ListNormalized>
                )}
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </AccordionList>
      </OffCanvas>
    </NavBarWrapper>
  );
};

NavBar.propTypes = {
  items: globalProps.menuLinks.isRequired,
};

export const ColourContextWrapper = ({ children }) => {
  const [transparency, setTransparency] = useState(true);

  return (
    <NavBarColourContext.Provider value={{ transparency, setTransparency }}>
      {children}
    </NavBarColourContext.Provider>
  );
};

ColourContextWrapper.propTypes = {
  children: globalProps.children.isRequired,
};

export const withColourContext = (BaseComponent) => (props) => (
  <NavBarColourContext.Consumer>
    {(values) => <BaseComponent {...props} {...values} />}
  </NavBarColourContext.Consumer>
);

export const contextProps = {
  setTransparency: PropTypes.func.isRequired,
  transparency: PropTypes.bool.isRequired,
};

NavBar.propTypes = contextProps;
export default withColourContext(NavBar);
