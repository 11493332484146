import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { FiBell } from 'react-icons/fi';
import theme from '../theme';
import Container from './Container';

const query = graphql`
  query {
    contentfulCallToAction(contentful_id: { eq: "3rFxVCMK6Hs1D1KGvLx39K"}){
      ...CallToActionDetail
    }
  }
`;

const AnnouncementWrapper = styled.a`
  background-image: linear-gradient(to right, ${theme.dark}, #182238);
  color: ${theme.contrast};
  box-shadow: 3px 14px 33px rgba(2,2,2,0.1);
  cursor: pointer;
  display: block;
  font-weight: 200;
  font-size: 0.88rem;
  padding: 1.5vh 2vw;
  transition-duration: 500ms;
  transition-property: background-color, color;

  svg {
    margin: 0 1rem;
  }

  &:hover,
  &:focus {
    color: ${theme.contrast};
  }

  @media (max-width: 866px){
    font-size: 0.79rem;
  }
`;

export default function Announcement() {
  return (
    <StaticQuery
      query={query}
      render={({ contentfulCallToAction }) => (
        <AnnouncementWrapper
          role="alert"
          target="_blank"
          href={contentfulCallToAction.buttonDestination}
          rel="noopener noreferrer"
        >
          <Container large style={{ padding: 0 }}>
            <FiBell />
            {contentfulCallToAction.title}
          </Container>
        </AnnouncementWrapper>
      )}
    />
  );
}
