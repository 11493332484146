import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Container from './Container';
import Grid from './Grid';
import { iterateMenuItems } from './Nav';
import { ListNormalized, ListNormalizedItem } from './List';
import SocialIcons from './SocialIcons';
import theme from '../theme';
import ButtonLink from './Button';

const FooterWrapper = styled.footer`
    background-color: ${theme.muted};
    padding: 3vh 2vw 1vh;
    position: relative;
`;

const FooterSubNav = styled.div`
    min-width: 8rem;
`;

const FooterSubNavTitle = styled.h4`
    font-size: 1.266em;
    font-weight: 800;
    text-transform: uppercase;
`;

const FooterSubNavListItemLink = styled(Link)`
  font-size: 1rem;
  text-decoration: none;
`;

const Copyright = styled.small`
  font-size: 16px;
  margin: .5rem 0;
  
  &::before {
    content: "©${() => new Date().getFullYear()}";
    margin-right: .5rem;
  }
`;

const footerData = graphql`
    query {

        ## dedicated footer menu entry
        menu: contentfulMenu(contentful_id: { eq: "s3flaY7CgOun9SR8HkZmv" }) {
            links {
                __typename
                ...on ContentfulPage { 
                    contentful_id
                    shortTitle 
                    slug
                }
                ...on ContentfulContactPage { 
                    contentful_id
                    title 
                    slug
                }
                ...on ContentfulMenu { 
                    contentful_id
                    heading 
                    links {
                        ...on ContentfulPage { 
                            title
                            shortTitle 
                            slug
                        }
                        ...on ContentfulContactPage { 
                            title 
                            slug
                        }
                    }
                }
            }
        }

        site {
            siteMetadata {
                title
                resourcesMenu {
                    href 
                    label
                }
            }
        }
    }
`;

const Footer = () => (
  <StaticQuery
    query={footerData}
    render={({ menu, site }) => {
      const { siteMetadata } = site;
      const { title, resourcesMenu } = siteMetadata;
      const { links } = menu;
      const menuLinks = iterateMenuItems(links);

      return (
        <FooterWrapper>
          <Container>
            <Grid justify="space-between">
              {/** populate contentful-controlled links */
                menuLinks.map(link => (
                  <FooterSubNav key={link.contentful_id}>
                    <FooterSubNavTitle>{link.label}</FooterSubNavTitle>
                    <ListNormalized>
                      {link.subMenu.map(sub => (
                        <ListNormalizedItem key={sub.href}>
                          <FooterSubNavListItemLink to={sub.href}>
                            {sub.label}
                          </FooterSubNavListItemLink>
                        </ListNormalizedItem>
                      ))}
                    </ListNormalized>
                  </FooterSubNav>
                ))}
              <FooterSubNav>
                <FooterSubNavTitle>Resources</FooterSubNavTitle>
                <ListNormalized>
                  {/** load subnav from config */
                    resourcesMenu.map(({ href, label }) => (
                      <ListNormalizedItem key={href}>
                        <FooterSubNavListItemLink to={href}>
                          {label}
                        </FooterSubNavListItemLink>
                      </ListNormalizedItem>
                    ))}
                </ListNormalized>
              </FooterSubNav>
              <FooterSubNav>
                <FooterSubNavTitle>Start Today</FooterSubNavTitle>
                <ButtonLink to="/demo">
                  {'Request a Demo'}
                </ButtonLink>
              </FooterSubNav>
            </Grid>
          </Container>
          <Container style={{ marginTop: '3rem' }}>
            <Grid justify="space-between">
              <Copyright>
                {title}
                <Link to="/privacy" style={{ marginLeft: '1rem' }}>
                  {'Privacy Statement'}
                </Link>
              </Copyright>
              <SocialIcons />
            </Grid>
          </Container>
        </FooterWrapper>
      );
    }}
  />
);

Footer.propTypes = {};
export default Footer;
