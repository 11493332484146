import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import theme from '../theme';

export const linkStyle = css`
  color: ${theme.primary};  
  &::before {
    background-color: ${theme.primary};
    content: '';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
  }
`;

export const ButtonLinkStyle = css`
  border: 1px solid;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  line-height: 3.5;
  margin-right: .75rem;
  text-decoration: none;
  transition-duration: 500ms;
  transition-property: 
    background-color, 
    border-color, 
    color;
  vertical-align: middle;

  @media (max-width: 567px){
    padding: 0 4vw;
  }

  ${({ fill, contrast }) => {
    if (fill) {
      return css`
        background-color: ${theme[fill] || theme.secondary};
        border-color: transparent;
        color: ${theme.contrast};
      `;
    }

    if (contrast) {
      return css`
        background-color: ${theme.tertiary};
        border-color: ${theme.tertiary};
        color: ${theme.contrast};
      `;
    }

    return css`
      background-color: transparent;
      border-color: ${theme.secondary};
      color: ${theme.secondary};
    `;
  }};
    
  ${({ small }) => {
    if (small) {
      return css`
        border-radius: 30px;
        padding: 0 2vw;
        font-size: 0.7em;
      `;
    }

    return css`
      padding: 0 2vw;
    `;
  }};

  &:hover, 
  &:focus {
    background-color: transparent;
    ${({ fill }) => {
    if (fill === 'primary'
      || fill === 'tertiary') {
      return css`
        border-color: ${theme.contrast};
        color: ${theme.contrast};
      `;
    }

    return css`
      border-color: ${theme.secondary};
      color: ${theme.secondary};
    `;
  }}
  }
`;

const ButtonLink = styled(Link)`${ButtonLinkStyle}`;
export const ButtonExternalLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  ${ButtonLinkStyle}
`;

export const Button = styled.button`${ButtonLinkStyle}`;
export default ButtonLink;
