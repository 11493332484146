import React from "react";
import { StaticQuery, graphql } from "gatsby";
import NavBar from "./NavBar";

const query = graphql`
  query {
    menu: contentfulMenu(contentful_id: { eq: "52zBLbrBs304uc92UhKeje" }) {
      links {
        __typename
        ... on ContentfulPage {
          shortTitle
          slug
        }
        ... on ContentfulContactPage {
          description {
            description
          }
          title
          slug
        }
        ... on ContentfulMenu {
          heading
          links {
            ... on ContentfulPage {
              description {
                description
              }
              shortTitle
              slug
            }
            ... on ContentfulContactPage {
              title
              slug
            }
          }
        }
      }
    }
    resources: allContentfulResourceArchive(
      sort: { fields: createdAt, order: ASC }
    ) {
      nodes {
        title
        description {
          description
        }
      }
    }
  }
`;

export function iterateMenuItems(links = []) {
  return links.map((link) => {
    const {
      __typename: type,
      links: nestedLinks,
      slug = "#",
      heading,
      shortTitle,
      title,
    } = link;

    const obj = {};

    obj.href = slug;
    obj.label = type !== "ContentfulMenu" ? shortTitle || title : heading;

    if (nestedLinks) {
      obj.subMenu = nestedLinks.map(
        ({ description, shortTitle: label, title: subTitle, slug: href }) => ({
          description,
          label: label || subTitle,
          href,
        })
      );
    }

    return obj;
  });
}

const Navigation = () => (
  <StaticQuery
    query={query}
    render={({ resources, menu }) => {
      const { links } = menu;
      const resourcesMenu = resources.nodes.map((item) => ({
        href: item.title.toLowerCase(),
        label: item.title,
        ...item,
      }));
      const menuLinks = iterateMenuItems(links);

      menuLinks[0].subMenu.push({
        href: "https://infoware.ca/",
        label: "Managed IT Services",
        description: {
          description:
            "Infoware provides professional Managed IT Services at fixed monthly fees for law firms in downtown Toronto and surrounding area.",
        },
      });

      menuLinks.splice(2, 0, {
        subMenu: resourcesMenu,
        label: "Resources",
        href: "#",
      });

      menuLinks.join();

      return (
        <React.Fragment>
          <NavBar transparent items={menuLinks} />
        </React.Fragment>
      );
    }}
  />
);

export default Navigation;
