import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import styled, { keyframes, css } from "styled-components";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import { withColourContext, contextProps } from "./NavBar";
import theme from "../theme";
import ButtonLink from "./Button";
import Container from "./Container";
import Grid, { versionStyles } from "./Grid";
import EmbeddedVideo, { getVideoHostLink } from "./EmbeddedVideo";

export const HeroWrapper = styled.header`
  align-items: center;
  background-size: cover;
  display: flex;
  overflow: hidden;
  padding: 15vh 0 5vh;
  position: relative;
  width: 100%;

  @media (max-width: 904px) {
    padding-top: 12vh;
  }

  ${({ version, state, toggle }) => {
    if (version && !state) toggle(true);
    return (
      versionStyles[version] ||
      css`
        background-color: ${theme.contrast};
        color: ${theme.dark};
      `
    );
  }};
`;

export const HeroContent = styled.div`
  margin: 0 auto;
  padding: 3vh 0 3vh;
  position: relative;
  text-align: ${({ withPicture }) => (withPicture ? "left" : "center")};
  width: 650px;

  @media (max-width: 1177px) {
    text-align: center;
  }

  @media (max-width: 772px) {
    text-align: left;
  }
`;

export const HeroTitle = styled.h1`
  color: inherit;
  margin: 1rem 0;
`;

export const HeroSubTitle = styled.div`
  font-size: 1.138em;
  opacity: 0.89;
  width: 90%;

  ${({ withPicture }) =>
    !withPicture &&
    css`
      margin-left: auto;
      margin-right: auto;
    `};

  p {
    color: inherit;
    font-size: inherit;
    line-height: 1.45;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const HeroImage = styled.div`
  min-width: 350px;
  max-width: 100%;
  position: relative;
  text-align: center;
  width: 40%;

  .gatsby-image-wrapper {
    border-radius: 2px;
    height: 0;
    padding-top: 70%;
    position: relative;
    z-index: 1;
  }

  @media (max-width: 1177px) {
    margin: 0 auto;
    min-width: 750px;
  }

  @media (max-width: 750px) {
    min-width: 400px;
    width: 100%;
  }

  @media (max-width: 500px) {
    min-width: 300px;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

export const ViewportOverlay = styled(DialogOverlay)`
  animation: ${fadeIn} 500ms forwards;
  background-color: rgba(2, 2, 2, 0.4);
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  top: 0;
  z-index: 1000;
`;

export const ModalWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  width: 100%;
`;

export const ModalContainer = styled(DialogContent)`
  height: auto;
  position: relative;
  width: 750px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ModalCloseButton = styled.button`
  background: transparent;
  border: 0;
  color: ${theme.contrast};
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 100%;
`;

export const SVGSpan = styled.span`
  display: inline-block;
  line-height: 1;
  height: 100%;
  margin-left: 0.5rem;
  vertical-align: middle;
`;

export const IconButton = styled.button`
  background-color: ${theme.primary};
  box-shadow: 5px 15px 75px ${theme.dark}50;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 6rem;
  width: 6rem;
  line-height: 1;
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  svg {
    fill: ${theme.contrast};
    stroke: transparent;
    margin-left: 0.25rem;
  }
`;

function Hero({
  title,
  subtitle,
  setTransparency,
  transparency,
  children,
  version,
  buttonLabel,
  buttonDestination,
  finePrint,
  picture,
  slug,
  videoUrl,
}) {
  /**
   * @NOTE
   * Test videos:
   * https://www.youtube.com/watch?v=1F9berxGWXE
   * https://youtu.be/1F9berxGWXE
   * https://vimeo.com/802820101
   *  https://vimeo.com/800948043
   */
  const embed = getVideoHostLink(videoUrl);
  const hasMedia = picture || embed;

  return (
    <HeroWrapper
      version={version.toLowerCase()}
      toggle={setTransparency}
      state={transparency}
    >
      <Container large>
        <Grid align="center">
          <HeroContent withSubTitle={subtitle} withPicture={picture}>
            {slug === "word-lx" && (
              <img src="/wordlx.png" alt="WordLX Logo" width="150" />
            )}
            {slug === "inform" && (
              <img src="/inform.png" alt="InForm Logo" width="150" />
            )}
            <HeroTitle>{title}</HeroTitle>
            {subtitle && (
              <HeroSubTitle withPicture={picture}>
                <p>{subtitle}</p>
              </HeroSubTitle>
            )}
            {finePrint && (
              <p style={{ marginBottom: "2rem" }}>
                <small>{finePrint}</small>
              </p>
            )}
            {children}
            {buttonDestination && (
              <ButtonLink contrast fill="tertiary" to={buttonDestination}>
                {buttonLabel}
              </ButtonLink>
            )}
          </HeroContent>
          {hasMedia && (
            <HeroImage>
              {embed ? (
                <EmbeddedVideo src={embed} />
              ) : (
                <Image
                  {...picture}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              )}
            </HeroImage>
          )}
        </Grid>
      </Container>
    </HeroWrapper>
  );
}

Hero.propTypes = {
  ...contextProps,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  version: PropTypes.string,
};

Hero.defaultProps = {
  version: "company",
  subtitle: "",
};

export default withColourContext(Hero);
