import React from 'react';
import PropTypes from 'prop-types';
import globalProps from '../props';

const RichText = ({ body, center }) => {
  if (!body) return null;
  const { html: __html } = body.childContentfulRichText;
  return (
    <div
      dangerouslySetInnerHTML={{ __html }}
      style={{ textAlign: center ? 'center' : 'left' }}
    />
  );
};

RichText.propTypes = {
  body: globalProps.body.isRequired,
  center: PropTypes.bool,
};

RichText.defaultProps = {
  center: false,
};

export default RichText;
